import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './UserInfo.styles';
import { useIntl } from 'react-intl';
import {
  selectTariffs,
  updateUser,
  cancelDeposit,
  unpairDevice,
  setDefaultPayment,
  deletePayment,
} from '../../../redux/store/userData/users';
import { selectAuth } from '../../../redux/store/userData/user';
import { selectReceiptLoading } from '../../../redux/store/userData/receipts';
import {
  openConfirmModal,
  openModal,
  openStatusModal,
} from '../../../redux/store/ui/common';
import UserIdentityInfo from './components/UserIdentityInfo';
import UserDeviceInfo from './components/UserDeviceInfo';
import UserDLInfo from './components/UserDLInfo';
import UserTariffInfo from './components/UserTariffInfo';
import UserPaymentsInfo from './components/UserPaymentsInfo';
import UserConsents from './components/UserConsent/UserConsents';
import UserIDInfo from './components/UserIDInfo';
import UserOrgInfo from './components/UserOrgInfo';
import UserContactInfo from './components/UserContactInfo';
import UserOrgContactInfo from './components/UserOrgContactInfo';
import { MODAL_EDIT_USER_LICENSE } from '../LicenseModal/EditLicenseModal';
import { MODAL_EDIT_USER_ID } from '../IDModal/EditIDModal';

const UserInfo: React.FC<{
  selectedUser: any;
  onClickTariffFile: () => void;
}> = ({ selectedUser, onClickTariffFile }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isReceiptLoading = useSelector(selectReceiptLoading);
  const tariffs = useSelector(selectTariffs);
  const isOrg = selectedUser?.userType === 'org';
  const user = useSelector(selectAuth);
  const selectedBrand = user.brands.find(
    (it) => it.id === selectedUser.brandId
  );

  const shouldShowSection = (isOrg, stepName) => {
    const firstSteps = isOrg
      ? selectedBrand?.kycSettings?.orgFirstSteps
      : selectedBrand?.kycSettings?.userFirstSteps;

    return firstSteps?.includes(stepName);
  };

  const showIDSection = shouldShowSection(isOrg, 'verifyIDDocument');
  const showDLSection = shouldShowSection(isOrg, 'verifyDriversLicense');
  const showTariff = shouldShowSection(isOrg, 'selectTariff');

  const handleEdit = (modal: string) => () => dispatch(openModal(modal));

  const handleConfirm =
    (action: any, titleId: string, destructive = false) =>
    () =>
      dispatch(
        openConfirmModal({
          destructive,
          title: intl.formatMessage({ id: titleId }),
          onConfirm: () => dispatch(action),
        })
      );

  const handleStatusUpdate =
    (field: string, statuses: string[], placeholder: string) => () =>
      dispatch(
        openStatusModal({
          defaultValue: selectedUser[field],
          statuses,
          placeHolder: placeholder,
          onConfirm: (selectedStatus) => {
            dispatch(updateUser(selectedUser.id, { [field]: selectedStatus }));
          },
        })
      );

  const onTariffUpdate = () => {
    let statuses = [];
    statuses = tariffs
      ?.filter((itm) => !itm?.archived)
      ?.map((tariff) => tariff.name);
    dispatch(
      openStatusModal({
        defaultValue: selectedUser?.tariff?.name,
        statuses: statuses,
        placeHolder: 'fleetadmin.users.tariff.update',
        textkeyPath: '',
        onConfirm: (selectedTariff) => {
          dispatch(
            updateUser(selectedUser.id, {
              tariff: {
                id: tariffs?.find((tariff) => tariff?.name === selectedTariff)
                  ?.id,
              },
            })
          );
        },
        onReset: () => {
          dispatch(
            updateUser(selectedUser.id, {
              tariff: {
                id: '',
              },
            })
          );
        },
      })
    );
  };

  const updateUserUpsellings = (code: string | null) =>
    dispatch(
      updateUser(selectedUser.id, {
        tariff: { ...selectedUser?.tariff, upsellings: code ? [{ code }] : [] },
      })
    );

  const onWorthinessUpdate = () => {
    dispatch(
      openStatusModal({
        defaultValue: selectedUser.creditworthinessData?.creditWorthinessStatus,
        statuses: ['open', 'verified'],
        onConfirm: (selectedStatus) => {
          dispatch(
            updateUser(selectedUser.id, {
              creditWorthinessStatus: selectedStatus,
            })
          );
        },
      })
    );
  };

  return (
    <>
      <div className="user-info">
        {!isOrg && (
          <UserIdentityInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditIdentity={handleEdit('UserIdentityModal')}
            onTitleUpdate={handleStatusUpdate(
              'title',
              ['MR', 'MRS'],
              'fleetadmin.users.info.title'
            )}
          />
        )}

        {!isOrg && (
          <UserContactInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditContact={handleEdit('UserContactModal')}
          />
        )}

        {isOrg && (
          <UserOrgInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditOrg={handleEdit('UserOrgModal')}
          />
        )}

        {isOrg && (
          <UserOrgContactInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditContact={handleEdit('UserOrgContactModal')}
          />
        )}

        <UserDeviceInfo
          selectedUser={selectedUser}
          intl={intl}
          onClickUnpair={(uuid: string) =>
            handleConfirm(
              unpairDevice(uuid),
              'fleetadmin.users.device.unpair.msg',
              true
            )()
          }
        />

        {showDLSection && (
          <UserDLInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditLicenseSettings={handleEdit('LicenseSettingsModal')}
            openAddLicenseModal={handleEdit('AddLicenseModal')}
            onDLStatusUpdate={handleEdit(MODAL_EDIT_USER_LICENSE)}
          />
        )}

        {showIDSection && (
          <UserIDInfo
            selectedUser={selectedUser}
            intl={intl}
            onEditIDSettings={handleEdit('IDSettingsModal')}
            openAddIDModal={handleEdit('AddIDModal')}
            onIDStatusUpdate={handleEdit(MODAL_EDIT_USER_ID)}
          />
        )}

        {showTariff && (
          <UserTariffInfo
            selectedUser={selectedUser}
            intl={intl}
            tariffs={tariffs}
            onTariffUpdate={onTariffUpdate}
            updateUserUpsellings={updateUserUpsellings}
            onClickTariffFile={onClickTariffFile}
          />
        )}

        <UserPaymentsInfo
          onCreditWorthinessUpdate={onWorthinessUpdate}
          selectedUser={selectedUser}
          intl={intl}
          allowedPaymentTypes={selectedBrand?.allowedPaymentTypes}
          isReceiptLoading={isReceiptLoading}
          onEditPayment={handleEdit('UserPaymentModal')}
          onClickSetDefaultPayment={(id: string) =>
            handleConfirm(
              setDefaultPayment(id),
              'fleetadmin.users.payment.default.msg'
            )()
          }
          onClickDeletePayment={(id: string) =>
            handleConfirm(
              deletePayment(id),
              'fleetadmin.users.payment.delete.msg',
              true
            )()
          }
          onClickCancelDeposit={handleConfirm(
            cancelDeposit(),
            'fleetadmin.users.deposit.refund.msg',
            true
          )}
          // @ts-ignore
          depositEnabled={selectedBrand?.sepaCanBeUnlockedByDeposit}
        />

        <UserConsents selectedUser={selectedUser} />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserInfo;
